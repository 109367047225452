import React from "react";

import { Container, Wrapper, Row, Box } from "../components/util";
import Layout from "../components/layout";
import Seo from "../components/seo";

const values = [
  {
    value: "Health and Safety",
    description:
      "We have developed our own Health and Safety manual and systems that ensure all risks associated with projects, offices, facilities, and functions are controlled.  It is our objective to regularly seek out and review HSE risks to mitigate any potential hazards identified at an early stage, and to monitor progress to ensure personnel and sub-contractors meet ABUILDTM expectations for safe, healthy, and environmentally sensitive practices.",
  },
  {
    value: "People",
    description:
      "We invest in our people by creating a friendly learning environment. We seek to maintain a healthy team culture based on honesty and respect, where every individual’s contribution is valued and recognised.",
  },
  {
    value: "Client Satisfaction",
    description:
      "We deliver an excellent quality of service to our clients. We respect our clients’ values and ensure we adhere to all health, safety, and environmental regulations both on site and within our own premises.  We deliver what we agree and often add value beyond what is expected.",
  },
  {
    value: "Responsiveness",
    description:
      "As a small consultancy our future depends on speed and flexibility to deliver quality solutions. We recognise change and move quickly and decisively to meet new challenges; enabling us to provide our clients and ourselves with a competitive edge.",
  },
];

const Values = (props) => (
  <Layout>
    <Seo title="Latest News from ABuild" pathname={props.location.pathname} />
    <Container>
      <Wrapper>
        <h1 className="-textCenter">ABuild Values</h1>
        {values.map((item, i) => {
          return (
            <Row
              key={i}
              gap={60}
              flipRow={i % 2 !== 0 ? true : false}
              alignCenter
            >
              <Box size={50}>
                <h2>{item.value}</h2>
                <p>{item.description}</p>
              </Box>
              <Box size={50}></Box>
            </Row>
          );
        })}
      </Wrapper>
    </Container>
  </Layout>
);

export default Values;
